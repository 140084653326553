<script setup lang="ts">
const route = useRoute();

useHead({
  title: 'ZapScale - The Easiest Customer Success Platform for B2B SaaS Companies',
  meta: [
    {
      name: 'description',
      content:
        'ZapScale is the best customer success platform for SaaS with easy integrations, prebuilt KPIs & playbooks to proactively address customer needs, reduce churn & boost upsell',
    },
    {
      property: 'og:description',
      content:
        'ZapScale is the best customer success platform for SaaS with easy integrations, prebuilt KPIs & playbooks to proactively address customer needs, reduce churn & boost upsell',
    },
    {
      property: 'og:title',
      content: 'ZapScale - Customer Success Management Platform for B2B SaaS',
    },
    {
      property: 'og:url',
      content: 'https://www.zapscale.com' + route.path,
    },
    {
      property: 'og:image',
      content: 'https://www.zapscale.com/images/logo.png',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: 'https://www.zapscale.com' + route.path,
    },
  ],
});

useJsonld(() => {
  return [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'ZapScale',
      description:
        'A single-source customer success management SaaS platform with 50 pre-built playbooks, 40 out-of-the-box KPIs, and unlimited user logins.',
      url: 'https://www.zapscale.com',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.zapscale.com/search-results?q={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'ZapScale',
      description:
        'A single-source customer success management SaaS platform with 50 pre-built playbooks, 40 out-of-the-box KPIs, and unlimited user logins.',
      url: 'https://www.zapscale.com/',
      logo: 'https://www.zapscale.com/images/Header_logo.svg',
      sameAs: [
        'https://www.facebook.com/zapscale',
        'https://www.linkedin.com/company/zapscale/',
        'https://twitter.com/ZapScale',
      ],
    },
  ];
});

const integrations = [
  {
    name: 'gmail',
    width: '153',
    height: '46',
  },
  // {
  //   name: 'outlook',
  //   width: '141',
  //   height: '55',
  // },
  {
    name: 'slack',
    width: '125',
    height: '32',
  },
  {
    name: 'intercom',
    width: '156',
    height: '47',
  },
  // {
  //   name: 'teams',
  //   width: '169',
  //   height: '48',
  // },
  // {
  //   name: 'calendar',
  //   width: '133',
  //   height: '53',
  // },
  {
    name: 'zendesk',
    width: '116',
    height: '30',
  },
  {
    name: 'segment',
    width: '149',
    height: '37',
  },
  {
    name: 'pendo',
    width: '128',
    height: '35',
  },
  {
    name: 'hubspot',
    width: '128',
    height: '37',
  },
  {
    name: 'jira',
    width: '100',
    height: '68',
  },
  {
    name: 'mixpanel',
    width: '128',
    height: '43',
  },
];

const categories = ref([
  {
    title: { text: 'AI-Augmented Inbox', class: 'w-[168px]' },
    panelText: 'All your emails automatically classified, prioritised and ready for response',
    icon: {
      name: 'tab-icon-1',
      width: '27',
      height: '27',
      class: 'w-[27px] h-[27px]',
    },
    image: {
      path: '/images/home/ai/inbox.webp',
      width: '339',
      height: '209',
      alt: 'inbox',
    },
  },
  {
    title: { text: 'AI Account Summary', class: 'w-[197px]' },
    panelText: 'No account analysis needed. All history and trends at your fingertips',
    icon: { name: 'tab-icon-2', width: '36', height: '27', class: 'w-[36px] h-[27px]' },
    image: { path: '/images/home/ai/summary.webp', width: '305', height: '232', alt: 'summary' },
  },
  {
    title: { text: 'AI Sentiment Analysis', class: 'w-[174px]' },
    panelText: 'Reads all your customer communication from all channels to give you the pulse of the customer',
    icon: { name: 'tab-icon-3', width: '32', height: '32', class: 'w-[32px] h-[32px]' },
    image: {
      path: '/images/home/ai/analysis.webp',
      width: '305',
      height: '179',
      alt: 'analysis',
    },
  },
  {
    title: { text: 'AI Churn Prediction', class: 'w-[212px]' },
    panelText: "Know who's at risk before it’s too late with ZapScale n-dimensional hyperspace",
    icon: { name: 'tab-icon-4', width: '23', height: '40', class: 'w-[23px] h-[40px]' },
    image: { path: '/images/home/ai/prediction.webp', width: '319', height: '210', alt: 'prediction' },
  },
  {
    title: { text: 'AI Upsell Forecast', class: 'w-[194px]' },
    panelText: 'Get your revenue forecast in order and know who is ready to upgrade',
    icon: { name: 'tab-icon-5', width: '29', height: '29', class: 'w-[29px] h-[29px]' },
    image: { path: '/images/home/ai/forecast.webp', width: '319', height: '210', alt: 'forecast' },
  },
]);

const selectedCategory = ref('AI-Augmented Inbox');

const showVideoPopup = ref(false);

const tabDiv = ref<HTMLDivElement>();
const listboxSpanRef = ref<HTMLSpanElement>();

const bottom = ref(0);
const width = ref(0);

const security = [
  'Bi-annual VAPT security audit',
  'Data access policy',
  'On-demand issue logging',
  'Audit logging & monitoring',
  'Complete encryption',
  'US data servers',
];

function openVideoPopup() {
  showVideoPopup.value = true;
}

function closeVideoPopup() {
  showVideoPopup.value = false;
}

function setCategory(val: string) {
  selectedCategory.value = val;
}

if (process.client) {
  const tabDivResizeObserver = new ResizeObserver((entries) => {
    if (entries.length) {
      bottom.value = entries[0].contentRect.height;
    }
  });

  const listboxBtnResizeObserver = new ResizeObserver((entries) => {
    if (entries.length) {
      width.value = entries[0].contentRect.width;
    }
  });

  onMounted(() => {
    if (tabDiv.value) {
      tabDivResizeObserver.observe(tabDiv.value);
    }

    if (listboxSpanRef.value) {
      listboxBtnResizeObserver.observe(listboxSpanRef.value);
    }
  });

  onUnmounted(() => {
    tabDivResizeObserver.disconnect();
    listboxBtnResizeObserver.disconnect();
  });
}

const selectedIndex = ref(0);
</script>

<template>
  <div class="h-[400px] bg-[#242424] max-[407px]:h-[430px] md:h-[550px] lg:h-[500px] xl:h-[597px]">
    <div class="container mx-auto">
      <div class="pb-12 pt-6 max-md:px-5 md:pt-8">
        <h1
          class="mx-auto mb-8 text-center font-merriweather text-2xl font-extrabold text-white max-md:leading-7 md:mb-12 md:w-[750px] md:text-3xl md:leading-[76px] lg:w-[800px] lg:text-6xl"
        >
          The <span class="text-[#FFB22F]">customer success platform</span> for B2B SaaS
        </h1>
        <h2
          class="mx-auto mb-8 text-center font-merriweather text-lg font-bold text-white max-md:leading-5 md:mb-16 md:w-4/5 md:text-3xl md:leading-[35px] lg:w-[846px]"
        >
          Easiest to use and fastest to implement, ZapScale gives the deepest customer insights to growing CS teams.
        </h2>
        <div class="flex items-center justify-center gap-x-4 md:gap-x-6">
          <NuxtLink
            to="/demo/#book-a-demo"
            class="flex h-[41px] w-[236px] items-center justify-between rounded-full bg-[#FF395B] px-3 py-2 text-xs font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B] md:px-6 md:text-base"
          >
            <span class="flex w-full items-center justify-between">
              Book a Demo
              <Icon name="solar:arrow-right-linear" />
            </span>
          </NuxtLink>
          <button
            @click="openVideoPopup"
            class="h-[41px] w-[236px] rounded-full border border-white bg-[#242424] px-3 py-2 text-xs font-semibold text-white hover:bg-white hover:text-[#242424] md:px-6 md:text-base"
          >
            <span class="flex items-center justify-between">
              Watch Product Video
              <Icon name="solar:arrow-right-linear" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container mx-auto">
    <div
      class="mx-auto -mt-[6rem] w-[85vw] rounded-2xl bg-white py-7 max-md:mb-3 md:-mt-[5rem] md:h-[213px] md:w-[70vw] xl:-mt-[8rem]"
    >
      <div class="mx-auto mb-5 flex h-fit w-fit items-center gap-x-2 max-[350px]:flex-col">
        <span class="h-fit w-fit text-lg font-medium md:text-[22px]"
          >G2 Accreditations <span class="max-[350px]:hidden">|</span></span
        >
        <div class="flex h-fit w-fit items-center justify-center gap-x-3 text-lg font-medium md:text-[22px]">
          4.8
          <div class="flex justify-center gap-x-1">
            <Icon
              name="ph:star-fill"
              v-for="i in 5"
              :key="i"
              :size="'20'"
              :color="'#4c51da'"
              class="max-md:h-3 max-md:w-3"
            />
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center gap-x-2 xl:gap-x-4">
        <NuxtImg src="/images/home/left.webp" alt="left" width="66" height="118" class="xl:h-[118px]" />
        <div class="flex flex-col items-center gap-x-2 gap-y-4 md:flex-row md:items-start md:justify-center xl:gap-x-4">
          <div class="grid grid-cols-3 items-start gap-x-2 xl:gap-x-4">
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_EasiestToUse_EaseOfUse.webp"
              alt="easiest-to-use"
              width="76"
              height="84"
            />
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_BestEstimatedROI_Small-Business_Roi.webp"
              alt="best-estimate-roi"
              width="76"
              height="84"
            />
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_BestMeetsRequirements_Small-Business_MeetsRequirements.webp"
              alt="best-meets-requirements"
              width="76"
              height="84"
            />
          </div>
          <div class="grid grid-cols-2 items-start gap-x-2 xl:gap-x-4">
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_FastestImplementation_Small-Business_GoLiveTime.webp"
              alt="fastest-implementation"
              width="97"
              height="108"
            />
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_EasiestSetup_Small-Business_EaseOfSetup.webp"
              alt="easiest-setup"
              width="97"
              height="108"
            />
          </div>
          <div class="grid grid-cols-3 items-start gap-x-2 xl:gap-x-4">
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_BestSupport_Small-Business_QualityOfSupport.webp"
              alt="small-business-quality-of-support"
              width="76"
              height="84"
            />
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_UsersMostLikelyToRecommend_Small-Business_Nps.webp"
              alt="users-most-likely-to-recommend"
              width="76"
              height="84"
            />
            <NuxtImg
              class="col-span-1"
              src="/images/home/g2/spring-badges/CustomerSuccess_HighPerformer_HighPerformer.webp"
              alt="high-performer"
              width="76"
              height="84"
            />
          </div>
        </div>
        <NuxtImg src="/images/home/right.webp" alt="right" width="66" height="118" class="xl:h-[118px]" />
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto">
      <div class="px-5">
        <hr class="mx-auto h-[1px] w-4/5 bg-[#E5E5E6] md:hidden" />

        <div class="py-10 md:py-14">
          <NuxtImg
            loading="lazy"
            :placeholder="[158, 22]"
            src="/images/landing-page/heart.svg"
            alt="Zapscale - Landing Page"
            width="158"
            height="22"
            class="mx-auto mb-6"
          />
          <CustomerLogoSlider />
        </div>

        <div class="mb-12 rounded-[35px] bg-[#FFC52F] px-3 pb-4 pt-10 md:mb-24 md:px-14 md:py-20">
          <span class="mx-auto mb-4 block w-fit text-center text-lg font-normal md:mb-8">
            ZAPSCALE MAKES CUSTOMER SUCCESS
          </span>
          <span
            class="mx-auto mb-3 block w-fit font-merriweather text-3xl font-black max-md:text-center md:mb-6 lg:text-6xl"
          >
            Easier, Faster and Deeper
          </span>
          <span
            class="mx-auto mb-5 block text-center text-[15px] font-normal md:mb-10 md:w-2/3 md:text-[25px] md:leading-[35px]"
          >
            Make your Customer Success the guiding light for your business with improved visibility, productivity and
            forecast. 
          </span>
          <div class="flex flex-col gap-y-12">
            <div
              class="bottom-box flex flex-col items-center justify-between gap-x-6 gap-y-4 rounded-3xl bg-white p-4 md:px-12 md:py-12 lg:flex-row"
            >
              <div class="lg:w-1/2">
                <div class="mb-4 flex items-center gap-3 max-xl:relative max-md:justify-between md:mb-6 md:gap-10">
                  <div
                    class="top-box-inside flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#FFC52F] md:h-[99px] md:w-[99px]"
                  >
                    <NuxtImg
                      loading="lazy"
                      src="/images/home/icon-1.svg"
                      :placeholder="[60]"
                      class="h-[20px] w-[20px] md:h-[60px] md:w-[60px]"
                      width="60"
                      height="60"
                      alt="thumbs-up"
                    />
                  </div>
                  <span
                    class="flex h-[40px] w-fit items-center font-merriweather text-lg font-bold max-xl:absolute max-md:-top-[2px] max-md:left-[52px] md:w-[334px] md:text-[35px] md:leading-[47px] md:max-xl:left-[105px]"
                    >Enhance customer visibility</span
                  >
                </div>
                <span class="mb-3 block w-full text-lg font-semibold xl:w-[497px]"
                  >With 150 data points from 6 sources, get a detailed insight into your customer.</span
                >
                <span class="mb-3 block text-[15px] font-semibold md:text-lg"
                  >Segment customers, track product adoption, value and more.</span
                >
                <ul class="mb-6 list-disc pl-3 text-[15px] font-normal md:mb-10 md:text-base">
                  <li>360 degree customer view with segmentation</li>
                  <li>Deepest health analytics with pre-built and custom KPIs</li>
                  <li>Track product adoption with zero dev effort</li>
                </ul>
                <div
                  class="mb-5 flex w-full flex-col justify-between gap-y-3 md:mb-10 md:w-[384px] md:flex-row md:items-start"
                >
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">02%</span>
                    <span class="block text-base font-bold md:w-[129px] md:text-xl">Churn Reduction</span>
                  </div>
                  <span class="hidden h-[85px] w-px bg-[#A7A7A7] md:block"></span>
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">40%</span>
                    <span class="block text-base font-bold md:w-[163px] md:text-xl">Increase in Product Adoption</span>
                  </div>
                </div>
                <NuxtLink
                  to="/features#customer-360"
                  class="block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B]"
                >
                  <span class="flex items-center justify-between">
                    Know More
                    <Icon name="solar:arrow-right-linear" />
                  </span>
                </NuxtLink>
              </div>
              <NuxtImg
                loading="lazy"
                :placeholder="[734, 494]"
                src="/images/home/enhance-customer-visibility.webp"
                width="734"
                height="494"
                class="lg:w-1/2"
                alt="Enhance customer visibility"
              />
            </div>
            <div
              class="bottom-box flex flex-col-reverse items-center justify-between gap-x-4 gap-y-6 rounded-3xl bg-white p-4 md:px-12 md:py-12 lg:flex-row"
            >
              <NuxtImg
                loading="lazy"
                :placeholder="[681, 522]"
                src="/images/home/improve-your-cs-team-productivity.webp"
                width="681"
                height="522"
                class="lg:w-1/2"
                alt="Improve CS team productivity"
              />
              <div class="lg:w-1/2">
                <div class="mb-4 flex items-center gap-3 max-xl:relative max-md:justify-between md:mb-6 md:gap-10">
                  <div
                    class="top-box-inside flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#FFC52F] md:h-[99px] md:w-[99px]"
                  >
                    <NuxtImg
                      loading="lazy"
                      :placeholder="[60]"
                      src="/images/home/icon-2.svg"
                      class="h-[20px] w-[20px] md:h-[60px] md:w-[60px]"
                      alt="automate"
                      width="60"
                      height="60"
                    />
                  </div>
                  <span
                    class="flex h-[40px] w-fit items-center font-merriweather text-lg font-bold max-xl:absolute max-md:-top-[2px] max-md:left-[52px] md:w-[334px] md:text-[35px] md:leading-[47px] md:max-xl:left-[105px]"
                    >Improve CS team productivity</span
                  >
                </div>
                <span class="mb-3 block w-full text-lg font-semibold xl:w-[497px]"
                  >Automate your customer success operations for improved communication, productivity and
                  scalability.</span
                >

                <ul class="mb-6 list-disc pl-3 text-base font-normal md:mb-10">
                  <li>Automated workflows and pre-built playbooks</li>
                  <li>Success plans for focused customer goals</li>
                  <li>Easy task management and customer prioritisation</li>
                </ul>
                <div
                  class="mb-5 flex w-full flex-col justify-between gap-y-3 md:mb-10 md:w-[448px] md:flex-row md:items-start"
                >
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">30%</span>
                    <span class="block text-base font-bold md:w-[179px] md:text-xl"
                      >Improvement in CS team productivity</span
                    >
                  </div>
                  <span class="hidden h-[85px] w-px bg-[#A7A7A7] md:block"></span>
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">90%</span>
                    <span class="block text-base font-bold md:w-[163px] md:text-xl"
                      >Reduction in data collation efforts</span
                    >
                  </div>
                </div>
                <NuxtLink
                  to="/features#success-playbook"
                  class="block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B]"
                >
                  <span class="flex items-center justify-between">
                    Know More
                    <Icon name="solar:arrow-right-linear" />
                  </span>
                </NuxtLink>
              </div>
            </div>
            <div
              class="bottom-box flex flex-col items-center justify-between gap-x-6 gap-y-4 rounded-3xl bg-white p-4 md:px-12 md:py-12 lg:flex-row"
            >
              <div class="lg:w-1/2">
                <div class="mb-4 flex items-center gap-3 max-xl:relative max-md:justify-between md:mb-6 md:gap-10">
                  <div
                    class="top-box-inside flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#FFC52F] md:h-[99px] md:w-[99px]"
                  >
                    <NuxtImg
                      loading="lazy"
                      :placeholder="[60]"
                      src="/images/home/icon-3.svg"
                      class="h-[20px] w-[20px] md:h-[60px] md:w-[60px]"
                      alt="analytics"
                      width="60"
                      height="60"
                    />
                  </div>
                  <span
                    class="flex h-[40px] w-fit items-center font-merriweather text-lg font-bold max-xl:absolute max-md:-top-[2px] max-md:left-[52px] md:w-[334px] md:text-[35px] md:leading-[47px] md:max-xl:left-[105px]"
                    >Deep Performance Analytics</span
                  >
                </div>
                <span class="mb-3 block w-full text-lg font-semibold xl:w-[497px]"
                  >Understand your customer and business performance trends over time and forecast revenue with improved
                  accuracy</span
                >
                <ul class="mb-6 list-disc pl-3 text-base font-normal md:mb-10">
                  <li>Get a holistic business overview</li>
                  <li>AI-powered churn and upsell prediction</li>
                  <li>Understand product and business trends</li>
                </ul>
                <div
                  class="mb-5 flex w-full flex-col justify-between gap-y-3 md:mb-10 md:w-[384px] md:flex-row md:items-start"
                >
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">90%</span>
                    <span class="block text-base font-bold md:w-[160px] md:text-xl">Revenue forecast accuracy</span>
                  </div>
                  <span class="hidden h-[85px] w-px bg-[#A7A7A7] md:block"></span>
                  <div class="max-md:flex max-md:items-end max-md:gap-3">
                    <span class="block text-3xl font-extrabold text-[#66B774] md:text-6xl">05%</span>
                    <span class="block text-base font-bold md:w-[163px] md:text-xl">Increase in upsell revenue</span>
                  </div>
                </div>
                <NuxtLink
                  to="/features#business-overview"
                  class="block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B]"
                >
                  <span class="flex items-center justify-between">
                    Know More
                    <Icon name="solar:arrow-right-linear" />
                  </span>
                </NuxtLink>
              </div>
              <NuxtImg
                loading="lazy"
                :placeholder="[676, 478]"
                src="/images/home/deep-performance-analytics.webp"
                width="676"
                height="478"
                class="lg:w-1/2"
                alt="Deep Performance Analytics"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-[#1771B9]">
      <div class="container mx-auto">
        <div class="px-5 pb-4 pt-10 text-white md:py-8 xl:px-14">
          <span class="mx-auto mb-4 block w-fit text-base font-normal md:mb-8">AI IN ZAPSCALE</span>
          <span
            class="mx-auto mb-3 block w-fit font-merriweather text-3xl font-black max-lg:text-center md:mb-6 xl:text-[35px]"
          >
            Super Charge Your CS With AI
          </span>
          <div class="bg-[url(/images/home/ai/ai-bg.svg)] bg-[length:95%] bg-center bg-no-repeat pb-6">
            <span class="mx-auto mb-8 block text-center text-lg font-normal">
              No more waiting for months to start tracking your customer and user health.<br />
              Get up and running on ZapScale the day you launch.
            </span>

            <div class="mx-auto mb-4 flex flex-col justify-center pt-6 max-md:items-center md:mb-10 md:flex-row">
              <div
                ref="tabDiv"
                id="ai-tab-group"
                class="relative mx-auto hidden h-[560px] justify-start rounded-3xl bg-white py-8 shadow-2xl md:flex md:w-[90%] md:pr-8"
              >
                <div class="flex w-full items-center justify-between md:gap-x-3 xl:gap-x-8">
                  <!-- tab button section start -->
                  <div class="flex flex-col justify-center rounded-xl text-[#19274D] md:w-2/5 xl:w-[320px]">
                    <div
                      v-for="(category, index) in categories"
                      :key="category.title.text"
                      :class="[
                        'relative flex h-[85px] w-full items-center rounded-br-2xl rounded-tr-2xl md:pl-5 xl:pl-10 ',
                        selectedCategory === category.title.text ? 'bg-[#FFC52F]' : '',
                      ]"
                    >
                      <button
                        :class="[
                          'flex w-full items-center gap-x-4 rounded-lg text-left text-base leading-5',
                          ' focus:outline-none',
                          selectedCategory === category.title.text ? 'font-bold' : 'font-medium',
                        ]"
                        @click="setCategory(category.title.text)"
                      >
                        <NuxtImg
                          loading="lazy"
                          :placeholder="[category.icon.width, category.icon.height]"
                          :src="`/images/home/tabs/${category.icon.name}.svg`"
                          :width="category.icon.width"
                          :height="category.icon.height"
                          :alt="category.icon.name"
                          :class="category.icon.class"
                        />
                        <span :class="category.title.class">{{ category.title.text }}</span>
                      </button>

                      <span
                        v-if="index !== categories.length - 1"
                        class="absolute bottom-0 mx-auto h-[1px] bg-[#DCDCDC] md:w-4/5 xl:w-[210px]"
                      ></span>

                      <div
                        v-if="selectedCategory === category.title.text"
                        class="bottom-box-inside absolute -left-16 flex h-[45px] w-[85px] -rotate-90 items-center justify-center rounded-tl-2xl rounded-tr-2xl bg-[#FFC52F] text-sm font-extrabold"
                      >
                        <!-- Step {{ index + 1 }} -->
                      </div>
                    </div>
                  </div>
                  <!-- tab button section end -->

                  <!-- tab panel section start -->
                  <div class="h-full md:w-3/5 xl:w-[850px]">
                    <div
                      class="flex h-full w-full flex-col items-center justify-center gap-y-6 rounded-xl bg-[#EAF2F9]"
                      v-if="selectedCategory === categories[0].title.text"
                    >
                      <span class="mx-auto w-3/5 text-center text-[25px] text-[#3A3A3C]">
                        {{ categories[0].panelText }}
                      </span>
                      <NuxtImg
                        loading="lazy"
                        class=""
                        :placeholder="[600, 370]"
                        src="/images/home/ai/inbox.webp"
                        width="600"
                        height="370"
                        alt="ai-inbox"
                      />
                    </div>
                    <div
                      class="flex h-full w-full flex-col items-center justify-center gap-y-6 rounded-xl bg-[#EAF2F9]"
                      v-if="selectedCategory === categories[1].title.text"
                    >
                      <span class="mx-auto w-3/5 text-center text-[25px] text-[#3A3A3C]">
                        {{ categories[1].panelText }}
                      </span>
                      <NuxtImg
                        preload
                        class="mix-blend-multiply"
                        :placeholder="[500, 380]"
                        src="/images/home/ai/summary.webp"
                        width="500"
                        height="380"
                        alt="ai-summary"
                      />
                    </div>
                    <div
                      class="flex h-full w-full flex-col items-center justify-center gap-y-6 rounded-xl bg-[#EAF2F9]"
                      v-if="selectedCategory === categories[2].title.text"
                    >
                      <span class="mx-auto w-[85%] text-center text-[25px] text-[#3A3A3C]">
                        {{ categories[2].panelText }}
                      </span>
                      <NuxtImg
                        preload
                        class="mix-blend-multiply"
                        :placeholder="[600, 355]"
                        src="/images/home/ai/analysis.webp"
                        width="600"
                        height="355"
                        alt="ai-analysis"
                      />
                    </div>
                    <div
                      class="flex h-full w-full flex-col items-center justify-center gap-y-6 rounded-xl bg-[#EAF2F9]"
                      v-if="selectedCategory === categories[3].title.text"
                    >
                      <span class="mx-auto w-3/4 text-center text-[25px] text-[#3A3A3C]">
                        {{ categories[3].panelText }}
                      </span>
                      <NuxtImg
                        preload
                        class="mix-blend-multiply"
                        :placeholder="[550, 361]"
                        src="/images/home/ai/prediction.webp"
                        width="550"
                        height="361"
                        alt="ai-prediction"
                      />
                    </div>
                    <div
                      class="flex h-full w-full flex-col items-center justify-center gap-y-6 rounded-xl bg-[#EAF2F9]"
                      v-if="selectedCategory === categories[4].title.text"
                    >
                      <span class="mx-auto w-3/5 text-center text-[25px] text-[#3A3A3C]">
                        {{ categories[4].panelText }}
                      </span>
                      <NuxtImg
                        preload
                        class="mix-blend-multiply"
                        :placeholder="[550, 361]"
                        src="/images/home/ai/forecast.webp"
                        width="550"
                        height="361"
                        alt="ai-forecast"
                      />
                    </div>
                  </div>
                  <!-- tab panel section end -->
                </div>
              </div>

              <div class="w-full rounded-2xl bg-white md:hidden">
                <!-- <span
                  class="block w-full rounded-t-2xl bg-[#FFC52F] py-2 text-center text-base font-semibold text-black"
                  >
                  Step {{ selectedIndex + 1 }}
                  </span
                > -->
                <span
                  class="mx-auto my-3 flex h-[52px] w-4/5 items-center justify-center text-center text-base font-semibold text-black"
                  >{{ categories[selectedIndex].title.text }}</span
                >
                <div class="mx-auto mb-2 flex w-1/2 items-center justify-center gap-x-4">
                  <span
                    v-for="i in categories.length"
                    :key="i"
                    class="block h-[16px] w-[16px] rounded-full"
                    :class="['focus:outline-none', i - 1 === selectedIndex ? 'bg-[#1671B8]' : 'bg-[#D9D9D9]']"
                  ></span>
                </div>
                <div class="px-2 pb-4 pt-2">
                  <Swiper
                    :modules="[SwiperEffectCreative]"
                    :slides-per-view="1"
                    :loop="false"
                    :effect="'creative'"
                    :creative-effect="{
                      prev: {
                        shadow: false,
                        translate: ['-100%', 0, -1],
                      },
                      next: {
                        translate: ['100%', 0, 0],
                      },
                    }"
                    @active-index-change="(swiper: any) => {
                  selectedIndex = swiper.activeIndex;
                  }"
                  >
                    <SwiperSlide v-for="(item, index) in categories" :key="index" class="px-2">
                      <div
                        class="flex h-[350px] animate-appear flex-col items-center justify-center gap-y-6 rounded-2xl bg-[#e9f2f9]"
                      >
                        <span class="mx-auto w-4/5 text-center text-sm text-[#3A3A3C]">{{ item.panelText }}</span>
                        <NuxtImg
                          preload
                          :placeholder="[item.image.width, item.image.height]"
                          :src="item.image.path"
                          :width="item.image.width"
                          :height="item.image.height"
                          :alt="item.image.alt"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <!-- <NuxtLink
            to="/demo/#book-a-demo"
            class="mx-auto mb-12 block h-[41px] w-[179px] rounded-full bg-white px-6 py-2 text-base font-semibold text-[#1771B9] hover:border hover:border-white hover:bg-[#1771B9] hover:text-white"
          >
            <span class="flex items-center justify-between">
              Get Started
              <Icon name="solar:arrow-right-linear" />
            </span>
          </NuxtLink> -->
        </div>
      </div>
    </div>

    <div class="container mx-auto">
      <div
        class="mx-auto flex flex-col gap-x-16 gap-y-10 px-5 py-10 md:w-4/5 md:items-center md:justify-evenly md:py-14 lg:flex-row"
      >
        <NuxtImg
          loading="lazy"
          :placeholder="[630, 500]"
          src="/images/home/get-started.webp"
          width="630"
          height="500"
          alt="get-started"
          class="w-4/5 max-md:mx-auto lg:w-3/5"
        />
        <div class="flex flex-col lg:w-2/5">
          <span
            class="mb-4 block font-merriweather text-3xl font-bold leading-9 xl:w-[400px] xl:text-[55px] xl:leading-[69px]"
            >Get started on your journey to make CS easy</span
          >
          <span class="mb-4 block text-xl font-normal leading-6 xl:text-[25px] xl:leading-9"
            >Track Your Most Important Metrics To Drive Growth And Loyalty</span
          >
          <NuxtLink
            to="/demo/#book-a-demo"
            class="block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B]"
          >
            <span class="flex items-center justify-between">
              Book a Demo
              <Icon name="solar:arrow-right-linear" />
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>

    <!-- testimonial section -->
    <LazyNewTestimonials />
  </div>

  <div class="bg-[#EFF7FD]">
    <div class="container mx-auto">
      <div class="px-5 py-10 text-[#19274D] md:px-14 md:py-20">
        <span class="mx-auto mb-4 block w-fit text-base font-normal md:mb-8">INTEGRATION</span>
        <span
          class="mx-auto mb-6 block text-center font-merriweather text-3xl font-bold max-md:leading-9 md:w-[607px] xl:text-[35px]"
          >ZapScale integrates easily with all your favourite apps</span
        >
        <div class="mb-4 hidden items-center justify-center gap-x-4 xl:flex">
          <div
            v-for="integration in integrations.slice(0, 5)"
            class="col-span-1 flex h-[92px] w-[226px] items-center justify-center rounded-2xl border-[1px] border-[#D7E4EE] bg-[#FDFDFD]"
          >
            <NuxtImg
              loading="lazy"
              :placeholder="[integration.width, integration.height]"
              :src="`/images/home/integrations/${integration.name}.svg`"
              :width="integration.width"
              :height="integration.height"
              :alt="integration.name"
            />
          </div>
        </div>
        <div class="mb-12 hidden items-center justify-center gap-x-4 xl:flex">
          <div
            v-for="integration in integrations.slice(5)"
            class="col-span-1 flex h-[92px] w-[226px] items-center justify-center rounded-2xl border-[1px] border-[#D7E4EE] bg-[#FDFDFD]"
          >
            <NuxtImg
              loading="lazy"
              :placeholder="[integration.width, integration.height]"
              :src="`/images/home/integrations/${integration.name}.svg`"
              :width="integration.width"
              :height="integration.height"
              :alt="integration.name"
            />
          </div>
        </div>

        <div class="mb-6 grid grid-cols-3 gap-4 xl:hidden">
          <div
            v-for="integration in integrations"
            class="col-span-1 flex h-[64px] items-center justify-center rounded-2xl border-[1px] border-[#D7E4EE] bg-[#FDFDFD] max-md:p-2"
          >
            <NuxtImg
              loading="lazy"
              :placeholder="[integration.width, integration.height]"
              :src="`/images/home/integrations/${integration.name}.svg`"
              :width="integration.width"
              :height="integration.height"
              :alt="integration.name"
              class="max-md:w-4/5"
            />
          </div>
        </div>

        <NuxtLink
          to="/integrations"
          class="mx-auto mb-10 block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B] md:mb-20"
        >
          <span class="flex items-center justify-between">
            View All
            <Icon name="solar:arrow-right-linear" />
          </span>
        </NuxtLink>
        <hr class="mx-auto mb-10 w-4/5 border-[0.5px] border-[#BED1E0] md:mb-20" />
        <span class="mx-auto mb-3 block w-fit text-base font-normal md:mb-8">SECURITY & COMPLIANCE</span>
        <span class="mx-auto mb-7 block w-fit text-center font-merriweather text-3xl font-bold md:mb-10 xl:text-[35px]">
          We make sure your data is safe and secure.
          <br />Data security is our top priority</span
        >
        <div
          class="mx-auto mb-10 flex w-fit flex-col items-center justify-center gap-x-6 gap-y-6 md:mb-16 lg:flex-row lg:gap-y-0"
        >
          <div class="flex items-center justify-center gap-x-6 lg:gap-x-8">
            <div class="flex w-[124px] flex-col items-center md:w-[200px] md:flex-row">
              <NuxtImg
                loading="lazy"
                :placeholder="[100, 100]"
                src="/images/home/soc.png"
                alt="soc"
                class="max-lg:w-[80px]"
                width="100"
                height="100"
              />
              <div class="max-md:pt-3 max-md:text-center md:pl-4">
                <span class="block text-sm font-bold text-blue-900 lg:whitespace-nowrap">SOC2 - TYPE II</span>
                <span class="text-xs font-medium tracking-widest">CERTIFIED</span>
              </div>
            </div>
            <div class="flex w-[124px] flex-col items-center md:w-[200px] md:flex-row">
              <NuxtImg
                loading="lazy"
                :placeholder="[95, 100]"
                src="/images/home/vapt.png"
                alt="vapt"
                class="max-lg:w-[80px]"
                width="95"
                height="100"
              />
              <div class="max-md:pt-3 max-md:text-center md:pl-4">
                <span class="block text-sm font-bold text-blue-900">VAPT</span>
                <span class="text-xs font-medium tracking-widest">COMPLIANT</span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center gap-x-6 lg:gap-x-8">
            <div class="flex w-[124px] flex-col items-center md:w-[200px] md:flex-row">
              <NuxtImg
                loading="lazy"
                :placeholder="[100, 100]"
                src="/images/home/gdpr.png"
                alt="gdpr"
                class="max-lg:w-[80px]"
                width="100"
                height="100"
              />
              <div class="max-md:pt-3 max-md:text-center md:pl-4">
                <span class="block text-sm font-bold text-blue-900">GDPR</span>
                <span class="text-xs font-medium tracking-widest">READY</span>
              </div>
            </div>
            <div class="flex w-[124px] flex-col items-center md:w-[200px] md:flex-row">
              <NuxtImg
                loading="lazy"
                :placeholder="[100, 100]"
                src="/images/home/casa.jpg"
                alt="casa"
                class="max-lg:w-[80px]"
                width="100"
                height="100"
              />
              <div class="max-md:pt-3 max-md:text-center md:pl-4">
                <span class="block text-sm font-bold text-blue-900">TIER 2</span>
                <span class="text-xs font-medium tracking-widest md:whitespace-nowrap">CASA VERIFIED</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto grid w-fit grid-cols-1 justify-center gap-x-16 gap-y-1 md:grid-cols-2 md:gap-y-3">
          <span
            v-for="item in security"
            :key="item"
            class="flex items-center gap-x-3 text-lg font-medium text-black md:text-[25px]"
          >
            <NuxtImg
              src="/images/home/shield.webp"
              loading="lazy"
              :placeholder="[24, 30]"
              width="24"
              height="30"
              alt="security-shield"
              class="h-[15px] w-[12px] md:h-[30px] md:w-[24px]"
            />
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-[#FFC52F]">
    <div class="container mx-auto">
      <div class="px-5 py-10 md:px-14">
        <span
          class="mx-auto mb-6 block text-center font-merriweather text-3xl font-bold text-black md:mb-8 xl:w-[971px] xl:text-[35px] xl:leading-[50px]"
        >
          Simplify your customer success
          <span class="block"> operations with the easiest customer success platform. </span>
        </span>
        <NuxtLink
          to="/demo/#book-a-demo"
          class="mx-auto block h-[41px] w-[179px] rounded-full bg-[#FF395B] px-6 py-2 text-base font-semibold text-white hover:border hover:border-[#FF395B] hover:bg-white hover:text-[#FF395B]"
        >
          <span class="flex items-center justify-between">
            Get Started
            <Icon name="solar:arrow-right-linear" />
          </span>
        </NuxtLink>
      </div>
    </div>
  </div>

  <div id="video-dialog " class="relative z-50" v-if="showVideoPopup">
    <div class="fixed inset-0 bg-black/40" aria-hidden="true"></div>

    <div class="fixed inset-0 flex items-center justify-center">
      <div
        class="relative my-auto aspect-video min-w-[90vw] rounded-3xl bg-white px-6 pb-6 pt-12 md:min-w-[65vw] md:max-w-2xl"
      >
        <button @click="closeVideoPopup" class="absolute right-3 top-3">
          <Icon name="system-uicons:cross-circle" size="32px" class="hover:text-[#6A6A6A]" />
        </button>

        <iframe
          src="https://21917371.fs1.hubspotusercontent-na1.net/hubfs/21917371/ZapScale%20Feature%20Video.mp4"
          title="video"
          allow="autoplay"
          class="mx-auto aspect-video w-full rounded-md border-2 border-[#FF395B]"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top-box-inside {
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
}

.bottom-box {
  box-shadow: 0px 32px 10px -20px rgba(0, 0, 0, 0.1);
}

.bottom-box-inside {
  box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
}

@media only screen and (min-width: 768px) {
  .tab-background {
    background: url('/images/home/vector-blue.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
  }
}
</style>
